<template>
    <v-container class="fixed">
        <v-container>
            <breadcrumbs :items="bread"></breadcrumbs>
            <div class="d-flex align-center">
                <header-name>{{ user.name }}</header-name>
                <div class="ml-auto">
                    <v-menu v-if="!deleted" offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                                Actions
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                @click.prevent="changeDelete"
                                :loading="deleteSubmit"
                                class="error--text"
                            >
                                <v-list-item-title>Delete</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                @click.prevent="$refs.resetPassword.display()"
                                class="primary--text"
                            >
                                <v-list-item-title>
                                    Reset Password
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </div>

            <users-reset-password ref="resetPassword" :user="user" />

            <!--Deleted user-->
            <v-alert v-if="deleted" type="warning">
                This user has been deleted
                <v-btn
                    small
                    v-tooltip="'Restore the user'"
                    @click.prevent="changeDelete"
                    :loading="deleteSubmit"
                    class="ml-2"
                    color="success"
                >
                    Restore
                </v-btn>
            </v-alert>

            <v-expansion-panels v-model="activePanel" class="mt-5" accordion>
                <information-panel />

                <!--Details-->
                <expansion-panel icon="mdi-account-details" header="Details">
                    <UserDetails :user="user" admin />
                </expansion-panel>

                <timesheets-panel />

                <holiday-panel />

                <expenses-panel />

                <!--Personal Details-->
                <expansion-panel
                    v-if="hasPermission('staffAdminHr')"
                    icon="mdi-account-key"
                    header="Personal Details"
                >
                    <user-personal-details />
                </expansion-panel>

                <!--HR Files-->
                <expansion-panel
                    v-if="hasPermission('staffAdminHr')"
                    icon="mdi-file-multiple"
                    header="HR Files"
                >
                    <v-sheet elevation="2" class="pa-4 mb-4" rounded>
                        <hr-files-table
                            :items="$page.props.user.hr_files"
                            :user="$page.props.user"
                        />
                    </v-sheet>
                    <user-hr-files />
                </expansion-panel>

                <!--Performance Logs-->
                <expansion-panel
                    v-if="hasPermission('staffAdminHr')"
                    icon="mdi-post-outline"
                    header="Performance Logs"
                >
                    <user-performance-logs />
                </expansion-panel>

                <!--Permissions-->
                <permissions-panel />

                <!--Training-->
                <expansion-panel
                    icon="mdi-school"
                    header="Training"
                    v-if="hasPermission('staffAdminTraining')"
                >
                    <training-comp
                        :trainings="trainings"
                        :admin="true"
                        :user="user"
                    >
                    </training-comp>
                </expansion-panel>
            </v-expansion-panels>
        </v-container>
    </v-container>
</template>

<script>
import HeaderName from "../../../../Comps/General/HeaderName";
import Breadcrumbs from "../../../../Comps/General/Breadcrumbs";
import UserDetails from "../../../../Comps/Staff/Personal/UserDetails";
import TrainingComp from "../../../../Comps/Staff/Personal/TrainingComp";
import UsersResetPassword from "../../../../Comps/Staff/Users/UsersResetPassword";
import AdminController from "../../../../Mixins/AdminController";
import Permissions from "../../../../Mixins/Permissions";
import ExpansionPanel from "../../../../Comps/Staff/Users/UsersShow/ExpansionPanel";
import InformationPanel from "../../../../Comps/Staff/Users/UsersShow/InformationPanel";
import PermissionsPanel from "../../../../Comps/Staff/Users/UsersShow/PermissionsPanel";
import HolidayPanel from "../../../../Comps/Staff/Users/UsersShow/HolidayPanel";
import ExpensesPanel from "../../../../Comps/Staff/Users/UsersShow/ExpensesPanel";
import UserPersonalDetails from "../../../../Comps/Staff/Personal/UserPersonalDetails";
import UserHrFiles from "../../../../Comps/Staff/Personal/UserHrFiles";
import UserPerformanceLogs from "../../../../Comps/Staff/Personal/UserPerformanceLogs";
import HrFilesTable from "../../../../Comps/Staff/Personal/HrFilesTable";
import TimesheetsPanel from "../../../../Comps/Staff/Users/UsersShow/TimesheetsPanel.vue";

export default {
    name: "UsersShow",
    components: {
        TimesheetsPanel,
        HrFilesTable,
        UserPerformanceLogs,
        UserHrFiles,
        ExpensesPanel,
        UserPersonalDetails,
        HolidayPanel,
        PermissionsPanel,
        InformationPanel,
        ExpansionPanel,
        UsersResetPassword,
        TrainingComp,
        UserDetails,
        Breadcrumbs,
        HeaderName,
    },
    props: ["user", "trainings"],
    mixins: [AdminController, Permissions],

    data() {
        return {
            bread: [
                {
                    text: "Users",
                    disabled: false,
                    link: this.route("staff.admin.users.index"),
                },
                {
                    text: this.user.name,
                    disabled: true,
                },
            ],
            active: this.user.active,
            deleteSubmit: false,
            activePanel: 0,
        };
    },

    methods: {
        changeDelete() {
            if (!confirm("Are you sure?")) {
                return;
            }

            this.deleteSubmit = true;

            this.$inertia.post(
                this.route("staff.admin.users.destroy", this.user.id),
                {
                    state: this.deleted ? "restore" : "delete",
                },
                {
                    only: ["user", "flash"],
                    onFinish: () => {
                        this.deleteSubmit = false;
                    },
                    preserveScroll: true,
                }
            );
        },
    },

    computed: {
        deleted() {
            return !!this.user.deleted_at;
        },
    },
};
</script>

<style scoped></style>
