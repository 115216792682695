<template>
    <v-sheet elevation="2" class="pa-2" rounded>
        <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            :loading="loading"
            sort-by="date"
            no-data-text="No performance logs found"
            sort-desc
        >
            <template v-slot:top>
                <v-text-field
                    outlined
                    dense
                    v-model="search"
                    label="Search..."
                />
            </template>
            <template v-slot:item.date="{ item }">
                {{ momentFormatShortDateTime(item.date) }}
            </template>

            <template v-slot:item.notes="{ item }">
                <div class="pre-wrap" v-text="item.notes" />
            </template>

            <template v-slot:item.options="{ item }">
                <div v-if="showOptions">
                    <v-btn
                        icon
                        elevation="1"
                        @click.prevent="$emit('edit', item)"
                        v-tooltip="'Edit'"
                        color="primary"
                        class="my-1"
                    >
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                        icon
                        elevation="1"
                        @click.prevent="$emit('delete', item)"
                        v-tooltip="'Delete'"
                        color="error"
                        class="my-1"
                    >
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
    </v-sheet>
</template>

<script>
import Permissions from "../../../Mixins/Permissions";

export default {
    name: "PerformanceLogsTable",

    mixins: [Permissions],

    props: {
        items: {
            type: Array,
            required: true,
        },

        showOptions: {
            type: Boolean,
            default: false,
        },

        loading: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            search: "",
            headers: [
                {
                    text: "Date",
                    value: "date",
                },
                {
                    text: "Title",
                    value: "title",
                },
                {
                    text: "Notes",
                    value: "notes",
                },
                {
                    text: "",
                    value: "options",
                    sortable: false,
                },
            ],
        };
    },
};
</script>

<style scoped></style>
