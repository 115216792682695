<template>
    <v-form @submit.prevent="save" :disabled="form.processing">
        <!--General-->
        <v-row
            v-for="group in $page.props.personalDetailsFields"
            :key="group.key"
        >
            <v-col>
                <v-card>
                    <v-card-title v-text="group.name" />
                    <v-card-text>
                        <v-row>
                            <v-col
                                v-for="field in group.fields"
                                :key="field.key"
                                cols="12"
                                sm="6"
                            >
                                <date-picker
                                    v-if="field.type === 'date'"
                                    v-model="form[group.key][field.key]"
                                    :name="field.name"
                                    outlined
                                    dense
                                    show-year
                                />
                                <v-text-field
                                    v-if="field.type === 'string'"
                                    v-model="form[group.key][field.key]"
                                    :label="field.name"
                                    outlined
                                    dense
                                />
                                <v-textarea
                                    v-if="field.type === 'text'"
                                    v-model="form[group.key][field.key]"
                                    :label="field.name"
                                    outlined
                                    dense
                                />
                                <v-select
                                    v-if="field.type === 'select'"
                                    v-model="form[group.key][field.key]"
                                    :label="field.name"
                                    outlined
                                    dense
                                    :items="field.options"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-btn
                    color="success"
                    :loading="form.processing"
                    :disabled="form.processing"
                    type="submit"
                >
                    Save
                </v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import DatePicker from "../../Master/DatePicker";
export default {
    name: "UserPersonalDetails",
    components: { DatePicker },

    data() {
        return {
            form: this.$inertia.form(this.$page.props.user.personal_details),
        };
    },

    watch: {
        "form.home_address.postcode"(value) {
            this.form.home_address.postcode = _.toUpper(value);
        },
    },

    methods: {
        save() {
            this.form.put(
                this.route(
                    "staff.admin.users.update-personal-details",
                    this.$page.props.user
                )
            );
        },
    },
};
</script>

<style scoped></style>
