export default {
    name: "PeopleController",

    data() {
        return {
            menu: [
                {
                    title: "Logistics",
                    icon: "mdi-clipboard-text",
                    route: "staff.logistics",
                },
                {
                    title: "Holiday",
                    icon: "mdi-calendar-range",
                    route: "staff.holidays.index",
                },
                {
                    title: "Expenses",
                    icon: "mdi-cash",
                    route: "staff.expense-sheets.index",
                },
                {
                    title: "Training",
                    icon: "mdi-school",
                    route: "staff.training",
                },
                {
                    title: "Timesheets",
                    icon: "mdi-table",
                    route: "staff.timesheets.index",
                },
            ],
        };
    },
    mounted() {
        this.generateMenu();
        this.$root.store.sideMenuTitle = "People";
    },

    methods: {
        generateMenu() {
            this.$root.store.sideMenu = this.menu;
        },
    },
};
