<template>
    <modal-template native v-model="show">
        <v-card>
            <v-card-title
                :style="{ 'background-color': 'orange' }"
                class="d-flex"
            >
                <div class="white--text">
                    Holiday bookings for {{ momentFormatDateYear(day) }}
                </div>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text class="mt-3">
                <div v-for="booking in holidays" :key="booking.id">
                    {{ booking.user.name }}
                    <v-chip x-small label v-if="booking.half_day">
                        {{ booking.morning ? "Morning" : "Afternoon" }}
                    </v-chip>
                </div>
            </v-card-text>
        </v-card>
    </modal-template>
</template>

<script>
import ModalTemplate from "../../../Master/ModalTemplate";
import LogisticsHolidayHelpers from "../../../../Mixins/Logistics/LogisticsHolidayHelpers";

export default {
    name: "LogisticsHolidayInfoModal",
    components: { ModalTemplate },
    mixins: [LogisticsHolidayHelpers],

    data() {
        return {
            show: false,
            day: null,
        };
    },

    computed: {
        holidays() {
            return this.getDaysHolidays(this.day);
        },
    },

    methods: {
        open(day) {
            this.day = day;
            this.show = true;
        },
    },
};
</script>

<style scoped></style>
