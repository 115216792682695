<template>
    <v-row>
        <v-col cols="12">
            <div class="d-flex mb-6">
                <v-btn color="success" small @click.prevent="createLog">
                    Create <v-icon>mdi-plus</v-icon>
                </v-btn>
            </div>

            <performance-logs-table
                :items="$page.props.user.performance_logs"
                show-options
                @delete="deleteLog"
                @edit="editLog"
                :loading="form.processing"
            />
        </v-col>

        <modal-template v-model="modal">
            <div class="text-h6 mb-4">
                {{ newLog ? "Create" : "Update" }} a Performance Log for
                {{ $page.props.user.name }}
            </div>

            <v-form @submit.prevent="save" :disabled="form.processing">
                <validation-observer>
                    <v-row>
                        <v-col cols="12">
                            <validation-provider
                                name="Title"
                                rules="required"
                                v-slot="{ errors }"
                            >
                                <v-text-field
                                    required
                                    dense
                                    outlined
                                    autofocus
                                    v-model="form.title"
                                    label="Title"
                                    :error-messages="errors"
                                />
                            </validation-provider>
                        </v-col>

                        <v-col cols="12">
                            <v-textarea
                                dense
                                outlined
                                rows="6"
                                v-model="form.notes"
                                label="Notes"
                            />
                        </v-col>

                        <v-col cols="12">
                            <date-time-picker
                                required
                                name="Date"
                                label="Date"
                                v-model="form.date"
                            />
                        </v-col>

                        <v-col cols="12">
                            <v-btn
                                type="submit"
                                color="success"
                                :loading="form.processing"
                            >
                                Save
                            </v-btn>
                        </v-col>
                    </v-row>
                </validation-observer>
            </v-form>
        </modal-template>
    </v-row>
</template>

<script>
import PerformanceLogsTable from "./PerformanceLogsTable";
import ModalTemplate from "../../Master/ModalTemplate";
import DateTimePicker from "../../Master/DateTimePicker";
import moment from "moment";
export default {
    name: "UserPerformanceLogs",
    components: { DateTimePicker, ModalTemplate, PerformanceLogsTable },

    data() {
        return {
            form: this.$inertia.form({}),
            modal: false,
            newLog: false,
        };
    },

    methods: {
        save() {
            if (this.newLog) {
                this.storeLog();
                return;
            }

            this.updateLog();
        },

        createLog() {
            this.modal = true;
            this.newLog = true;
            this.form = this.$inertia.form({
                title: "",
                notes: "",
                date: moment(),
            });
        },

        storeLog() {
            this.form.post(
                this.route(
                    "staff.admin.users.performanceLogs.store",
                    this.$page.props.user
                ),
                {
                    onSuccess: () => {
                        this.modal = false;
                    },
                    preserveScroll: true,
                }
            );
        },

        updateLog() {
            this.form.put(
                this.route("staff.admin.users.performanceLogs.update", [
                    this.$page.props.user,
                    this.form.id,
                ]),
                {
                    onSuccess: () => {
                        this.modal = false;
                    },
                    preserveScroll: true,
                }
            );
        },

        editLog(log) {
            this.modal = true;
            this.newLog = false;
            this.form = this.$inertia.form(log);
        },

        deleteLog(log) {
            if (!confirm(`Are you sure you want to delete ${log.title}?`)) {
                return;
            }

            this.form.delete(
                this.route("staff.admin.users.performanceLogs.destroy", [
                    this.$page.props.user.id,
                    log.id,
                ]),
                {
                    preserveScroll: true,
                }
            );
        },
    },
};
</script>

<style scoped></style>
