<template>
    <modal-template v-model="modal" :persistent="step === 2">
        <v-slide-x-transition mode="out-in">
            <div v-if="step === 1" key="step1">
                <div class="text-h6 mb-4">Create User</div>
                <form novalidate @submit.prevent="submit">
                    <validation-observer ref="form">
                        <v-row>
                            <v-col cols="12" md="6">
                                <text-input
                                    v-model="form.first_name"
                                    name="First Name"
                                    :counter="255"
                                    label="First Name"
                                    autofocus
                                    class="required"
                                    required
                                    rules="required|max:255"
                                >
                                </text-input>
                            </v-col>
                            <v-col cols="12" md="6">
                                <text-input
                                    rules="required|max:255"
                                    v-model="form.last_name"
                                    name="Last Name"
                                    :counter="255"
                                    label="Last Name"
                                    class="required"
                                    required
                                >
                                </text-input>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <text-input
                                    rules="required|email"
                                    v-model="form.email"
                                    name="Email"
                                    type="email"
                                    label="Email"
                                    class="required"
                                    required
                                >
                                </text-input>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-checkbox
                                    v-model="form.create_google_account"
                                    label="Create Google Account"
                                />
                            </v-col>
                        </v-row>
                    </validation-observer>

                    <v-btn
                        color="success"
                        :loading="loading"
                        class="mt-2"
                        type="submit"
                    >
                        Create
                    </v-btn>
                </form>
            </div>
            <div v-if="step === 2" key="step2">
                <div class="text-h6">New user created! 🎉</div>
                <div class="text-body-2 mb-4">
                    The new users details and password are below. Dont forget to
                    copy the password now as it will not be available again
                </div>
                <div>
                    Name: <strong>{{ user.name }}</strong>
                </div>
                <div>
                    Email: <strong>{{ user.email }}</strong>
                </div>
                <div>
                    Password: <strong>{{ newPassword }}</strong>
                    <v-icon
                        @click="copyPassword"
                        small
                        color="success"
                        class="ml-1"
                        style="vertical-align: baseline"
                        v-tooltip="'Click to copy'"
                        >mdi-content-copy
                    </v-icon>
                </div>

                <v-btn @click="modal = false" color="success" class="mt-4">
                    Done
                </v-btn>
            </div>
        </v-slide-x-transition>
    </modal-template>
</template>

<script>
import ModalTemplate from "../../Master/ModalTemplate";
import CopyToClipboard from "../../../Mixins/CopyToClipboard";
import TextInput from "../../Forms/TextInput.vue";

export default {
    name: "CreateUserModal",
    components: { TextInput, ModalTemplate },
    mixins: [CopyToClipboard],

    data() {
        return {
            form: this.$inertia.form({
                first_name: "",
                last_name: "",
                email: "",
                create_google_account: true,
            }),
            loading: false,
            modal: false,
            step: 1,
            newPassword: "1234",
            user: {
                name: "Joe SH",
                email: "joe@pytch.co.uk",
            },
        };
    },

    watch: {
        modal(value) {
            if (!value) {
                setTimeout(() => {
                    this.step = 1;
                    this.newPassword = null;
                    this.user = null;
                    this.$refs.form?.reset();
                    this.form.reset();
                }, 1000);
            }
        },
    },

    methods: {
        create() {
            this.modal = true;
        },
        copyPassword() {
            this.copy(this.newPassword);
            this.$toast.success("Password copied");
        },
        async submit() {
            if (!(await this.$refs.form.validate())) {
                this.$toast.error("Please check for errors", {
                    duration: 5000,
                });

                return;
            }

            this.loading = true;

            this.$http
                .post(this.route("staff.admin.users.store"), this.form)
                .then((response) => {
                    this.step = 2;
                    this.user = response.data.user;
                    this.newPassword = response.data.password;
                })
                .catch((error) => {
                    this.$page.props.flash.errors = error.response.data.errors;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style scoped></style>
